// eslint-disable-next-line import/no-cycle
import requestService from "../requestService";

const prefix = '/offers';

export default {
  async getOffers(params) {
    const response = await requestService.get(`${prefix}`, {
      ...params,
    });
    return response?.data;
  },
  async getOffer(offerId) {
    const response = await requestService.get(`${prefix}/${offerId}`);
    return response?.data?.data;
  },
  async addOffer(payload) {
    const response = await requestService.post(`${prefix}`, payload, {}, false);
    return response?.data?.data;
  },
  async updateOffer(offerId, payload) {
    const response = await requestService.post(`${prefix}/${offerId}`, payload);
    return response?.data?.data;
  },
  async deleteOffer(offerId) {
    const response = await requestService.delete(`${prefix}/${offerId}`);
    return response?.data?.data;
  },
};
